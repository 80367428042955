'use client'

import { useRef, useEffect } from 'react';
import { register } from 'swiper/element/bundle';

export default function HomeHeroSection({ banners }) {
    const swiperElRef = useRef(null);

    useEffect(() => {
        register();
        if (banners && banners.length > 0) {
            const swiperContainer = swiperElRef.current;
            const params = {
                slidesPerView: '1',
                spaceBetween: 0,
                centeredSlides: false,
                speed: 800,
                grabCursor: false,
                loop: true,
                freeMode: false,
                mousewheel: false,
                touchStartPreventDefault: false,
                parallax: true,
                direction: 'horizontal',
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '.swiperone-button-next',
                    prevEl: '.swiperone-button-prev',
                },
                effect: `fade`,
                fadeEffect: {
                    crossFade: true
                }
            };
            Object.assign(swiperContainer, params);
            swiperContainer.initialize();
        }    }, []);

    return (
        <section id="slidehero">
 {
                banners && banners.length > 0
                    ?
                    <div className="section over-hide full-height">
                        <div className="section full-height swiper-bakery">
                            <swiper-container ref={swiperElRef} init="false">
                                {
                                    banners.map((item, index) =>
                                        <swiper-slide className='swiper-slide full-height' key={index}>
                                            <div
                                                className="section full-height full-height-xl slide-bg-image bg-image"
                                                style={{
                                                    backgroundImage: `url("${item.banner.imageUrl}")`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'cover'
                                                }}>
                                                <div className="hero-center-section">
                                                    <div className="main-box">
                                                        <div className="auto-container">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 fade-hero">
                                                                    <h2 data-swiper-parallax-x="400" data-swiper-parallax-duration="800">ESTOQUE COMPLETO!</h2>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                                <div className="col-md-12 fade-hero theme-mt-20"
                                                                    data-swiper-parallax-x="300" data-swiper-parallax-duration="400">
                                                                    <a href="/estoque" title='Confira o maior estoque de semi-novos do Brasil.' className="btn-mobile btn-lblue btn-arrow">
                                                                        <span>Confira! <i className="mdi mdi-plus"></i></span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    )
                                }
                            </swiper-container>
                            <div className="swiperone-button-prev fade-hero"><img src="fonts/arrow-left.svg" alt="Próximo Slider" />
                            </div>
                            <div className="swiperone-button-next fade-hero"><img src="fonts/arrow-right.svg" alt="Slider Anterior" />
                            </div>
                        </div>
                    </div>
                    : null
                }
        </section>
    );
}