'use client'

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { NumericFormat } from 'react-number-format';
import { FallBackImage } from '../commons/fallback-image';



export default function HomeStockSection({ ads }) {
    const swiperRef = useRef();
    return (
        <section className=" theme-pt-40 theme-pb-20 theme-pl-30 theme-pr-30">
            <div className="auto-container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-10">
                        <div className="site-heading text-left">
                            <h2>NOSSO ESTOQUE</h2>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                {
                    ads && ads.items && ads.items.length > 0
                        ? <div className="row">
                            <div className="col-md-12">
                            <Swiper
                                    id='listCars'
                                    modules={[Navigation]}
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    centeredSlides={true}
                                    autoplay={false}
                                    loop={true}
                                    navigation={true}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                            spaceBetween: 30,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 25,
                                        },
                                        1250: {
                                            slidesPerView: 4,
                                            spaceBetween: 25,
                                            // centeredSlides: true
                                        },
                                        1500: {
                                            slidesPerView: 4,
                                            spaceBetween: 25,
                                        },    
                                    }}                                    onSwiper={(swiper) => { swiperRef.current = swiper; }}
                                >
                                    <div className="swiper-wrapper">
                                        {
                                            ads.items.map((item, index) => {
                                                const adLink = `anuncio/${item.category}/${`${item.brand.split(' ').join('-').toLowerCase()}-${item.model.split(' ').join('-').toLowerCase()}`}/${item.yearModel}/${item.id}`;
                                                const adTitle = `${item.brand} ${item.model}`;
                                                const adWhatsapp = `https://api.whatsapp.com/send?phone=5511989333737&amp;text=Olá, gostaria de informações sobre ${item.brand} ${item.model} - Link do Veículo: ${adLink}`;

                                                return <SwiperSlide className='theme-mb-30' key={index}>
                                                    <div className="filter-card-item position-relative overflow-hidden rounded bg-white">
                                                        <a href={adLink} title={adTitle} className="icon-btn compare-btn position-absolute">
                                                            <i className="mdi mdi-plus"></i>
                                                        </a>
                                                        <a href={adWhatsapp} title={adTitle} target="_blank" className="icon-btn wish-btn position-absolute">
                                                            <i className="mdi mdi-whatsapp"></i>
                                                        </a>
                                                        <span className="date position-absolute">{`${item.yearFabrication} - ${item.yearModel}`}</span>
                                                        <div className="feature-thumb position-relative overflow-hidden" style={{ height: '220px' }}>
                                                            <a href={adLink} title={adTitle}>
                                                                <FallBackImage
                                                                    className='img-fluid'
                                                                    style={{
                                                                        width: '100%',
                                                                        objectFit: 'cover',
                                                                        position: 'absolute'
                                                                    }}
                                                                    alt={adTitle}
                                                                    title={adTitle}
                                                                    src={item.mainPhoto ? item.mainPhoto : "/image/no-image.jpeg"} />
                                                            </a>
                                                        </div>
                                                        <div className="filter-card-content">
                                                            <div className="price-btn text-start position-relative">
                                                                <span className="small-btn-meta">
                                                                    {
                                                                        item.price ?
                                                                            <NumericFormat
                                                                                className="small-btn-meta"
                                                                                style={{ fontSize: '14px' }}
                                                                                value={item.promotionalPrice ? item.promotionalPrice : item.price}
                                                                                displayType={'text'}
                                                                                thousandSeparator="."
                                                                                decimalSeparator=","
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                prefix={`${item.promotionalPrice ? 'Por R$ ' : 'R$ '}`} />
                                                                            : 'consulte-nos'
                                                                    }
                                                                </span>
                                                            </div>
                                                            <span className="meta-content mt-4 d-block"><strong>{item.brand}</strong></span>
                                                            <a href={adLink} title={adTitle} className="mt-1 d-block">
                                                                <h5
                                                                    style={{
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                        display: 'inline-block',
                                                                        width: '100%'
                                                                    }}>{item.version}</h5>
                                                            </a>
                                                            <div className="clearfix d-block w-100 theme-pt-10"></div>
                                                            <ul>
                                                                <li>
                                                                    <i className="mdi mdi-speedometer"></i>
                                                                    {
                                                                        item.mileage ?
                                                                            <NumericFormat
                                                                                value={item.mileage}
                                                                                displayType={'text'}
                                                                                thousandSeparator="."
                                                                                decimalSeparator=","
                                                                                decimalScale={0}
                                                                                fixedDecimalScale={true}
                                                                                suffix=' km' />
                                                                            : ' - km'
                                                                    }
                                                                </li>
                                                                <li><i className="mdi mdi-car-shift-pattern"></i>{item.transmission}</li>
                                                                <li><i className="mdi mdi-gas-station"></i>{item.fuel}</li>
                                                                <li><i className="mdi mdi-car-door"></i>{item.doors ? `${item.doors} Portas` : 'n/i'}</li>
                                                            </ul>
                                                            <a href={adLink} title={adTitle} className="btn outline-btn btn-sm d-block">VER MAIS DETALHES</a>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            }
                                            )
                                        }
                                    </div>
                                    <div className="swiper-button-prev" onClick={() => { swiperRef.current.slidePrev(); }}>
                                        <img src="fonts/arrow-left.svg" alt="Próximo Slider" />
                                    </div>
                                    <div className="swiper-button-next" onClick={() => { swiperRef.current.slideNext(); }}>
                                        <img src="fonts/arrow-right.svg" alt="Slider Anterior" />
                                    </div>
                                </Swiper>
                            </div>
                        </div>
                        : null
                }
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-md-12 theme-mt-10 text-left">
                        <a href="./estoque" title="Veja nosso estoque completo" className="btn btn-orange btn-arrow ">
                            <span>Estoque Completo<i className="mdi mdi-car"></i></span>
                        </a>
                    </div>
                </div>
            </div>
        </section >
    );
}