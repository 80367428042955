'use client'

import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

export default function HomeTestimonialSection() {
    return (
        <section className=" theme-pt-40 theme-pb-40 theme-pl-30 theme-pr-30">
            <div className="auto-container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 theme-mb-30">
                        <div className="site-heading text-left">
                            <h2>DEPOIMENTOS</h2>
                            <p>Opinião de alguns de nossos clientes</p>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mr-auto ml-auto">
                        <Swiper
                            id='testimonial'
                            modules={[Navigation]}
                            autoplay={true}
                            spaceBetween={30}
                            loop={true}
                            speed={4500}
                        >
                            <div className='swiper-wrapper'>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Ótimo atendimento, sempre muito atencioso, já é o segundo carro que compro com eles e sempre tudo muito certo.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Caio Donizete</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Segundo carro que compro com eles ótimo. Atendimento.  Obrigado pessoal. Sucesso pra vocês sempre.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Beatriz Santos</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Tive um atendimento excelente, vendedor Alessandro muito atencioso, estão de parabéns!!</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Marcelo Santos Lisboa</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Loja 10! Vendedores excelentes, atenciosos e educados! Comprei meu carro lá esse mês e fiquei muito satisfeita!</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Cleria Regina Leite</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Lugar fantástico, recepção maravilhosa, atendimento muito bom, avaliam seu usado com seriedade, competência e comprometimento de forma justa, vale conferir ❤️❤️</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Hilda Buarque</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Recomendo 100% !! Os caras tem um atendimento impecavel, uma atenção sem igual!! Melhor avaliação e negociação do meu usado na troca!! Financiamento ficou top, taxa de juros baixa!! Me ajudaram em tudo!!</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Marcos Vinicius</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Agradeço primeiramente à DEUS por ter permitido essa empresa AV Veículos no mercado, atendimento de excelência, onde possibilitaram a me e meu esposo a compra do nosso primeiro carro. E olha, carro de qualidade, tudo certinho✨ Mais do que recomendo😍 Continuem realizando sonhos🚗🎀</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Raquel Blanche</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Bom compramos um Agile 2012 com a AV VEÍCULOS E ficamos muito satisfeitos com o serviço prestado pela agência, eu e meu marido ficamos receosos pois estávamos procurando a tempos e a maioria das agências infelizmente agem de má fé com o cliente, mas sentimos total segurança e honestidade com eles e fechamos negócio, nosso agradecimento, fizeram de tudo para atender as nossas necessidades com a compra. Então super indico esta agência, obrigada AV VEÍCULOS.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Mary Alves</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Atendimento TOP. Carro TOP. Muito feliz pela compra e pelo atendimento.</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Daniel Solai</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonial-item'>
                                    <div className="tcontent">
                                        <blockquote>
                                            <p>Eu recomendo muito a AV veículos! São muito atenciosos, honestos, com carros de qualidade. Recebi todo o suporte para a compra e troca do meu carro. Eu pesquisei bastante, e a AV veículos foi a escolha certa!</p>
                                        </blockquote>
                                        <div className="testimonial-arrow-down"></div>
                                        <div className="testimonial-author">
                                            <p><strong className="font-weight-extra-bold">Daniela</strong></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}